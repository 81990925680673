import { render, staticRenderFns } from "./suggestion-list-filter.vue?vue&type=template&id=6cd09b21&scoped=true&"
import script from "./suggestion-list-filter.vue?vue&type=script&lang=js&"
export * from "./suggestion-list-filter.vue?vue&type=script&lang=js&"
import style0 from "./suggestion-list-filter.vue?vue&type=style&index=0&id=6cd09b21&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd09b21",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VIcon,VImg,VTextField,VWindow,VWindowItem})
