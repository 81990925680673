<template>
  <div class="d-flex flex-column full-height">
    <div
      v-if="displaySearch || computedSearchText.length || categorized || computedCategory"
      class="d-flex flex-row align-center px-4"
    >
      <v-btn
        v-if="computedCategory && !hideCategories"
        @click="resetCategory"
        icon
        class="mr-4"
      >
        <v-icon>{{$icon('i.ArrowLeft')}}</v-icon>
      </v-btn>
      <v-text-field
        data-cy="search-field"
        class="mb-2"
        dense
        outlined
        autofocus
        clearable
        hide-details
        :loading="loading"
        :label="label"
        v-model="computedSearchText"
        :prepend-inner-icon="$icon('i.Search')"
      />
      <slot name="append-search" />
    </div>
    <v-window
      v-if="!loading || categories.length || items.length"
      :vertical="vertical"
      :value="activeWindow"
    >
      <v-window-item class="flex-column full-height">
        <suggestion-list
          ref="categories"
          :can-edit-column="true"
          :items="categories"
          show-icon
          show-detail
          mixed
          @click:item="clickCategory"
          @end-reached="$emit('categories-end-reached', $event)"
        >
          <template v-slot:append-action="{ item }">
            <slot
              name="append-action"
              :item="item"
            />
            <v-icon>{{$icon("i.ChevronRight")}}</v-icon>
          </template>
        </suggestion-list>
      </v-window-item>
      <v-window-item class="flex-column full-height">
        <suggestion-list
          ref="items"
          :can-edit-column="true"
          v-if="items.length || loading"
          :items="items"
          :icon-props="iconProps"
          show-icon
          show-detail
          mixed
          @click:item="$emit('click:item', $event)"
          @end-reached="$emit('items-end-reached', $event)"
        >
          <template v-slot:prepend-action="{ item }">
            <slot
              name="prepend-action"
              :item="item"
            />
          </template>
          <template v-slot:append-action="{ item }">
            <slot
              name="append-action"
              :item="item"
            />
          </template>
        </suggestion-list>
        <div
          v-else
          class="d-flex flex-column align-center full-height"
        >
          <v-img
            class="my-4 flex-shrink-1"
            contain
            max-width="50%"
            src="@/assets/no-results.svg"
          />
          <div class="text-h4">
            {{$t("t.NoResult")}}
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
export default {
  components: {
    SuggestionList: () => import('@/pages/search/components/suggestion-list')
  },
  computed: {
    activeWindow () {
      return (!this.categorized || this.computedCategory || this.computedSearchText.length || this.hideCategories) ? 1 : 0
    },
    categorized () {
      return this.categories.length > 1
    },
    computedCategory: {
      get () {
        return this.category
      },
      set (v) {
        this.$emit('update:selected-category', v)
        this.category = v
      }
    },
    currentPage: {
      get () {
        return this.selectedPage
      },
      set (page) {
        this.$emit('update:selected-page', Number(page))
      }
    },
    displayPagination () {
      return this.totalItems > this.pageSize
    },
    computedSearchText: {
      get () {
        return this.innerSearchText ?? ''
      },
      set (text) {
        this.innerSearchText = text
        this.vertical = !this.computedCategory
        this.$emit('update:search-text', text ?? '')
      }
    },
    selectedItems: {
      get () {
        return this.selected
      },
      set (selected) {
        this.$emit('update:selected', selected)
      }
    }
  },
  data () {
    return {
      category: undefined,
      innerSearchText: '',
      vertical: false
    }
  },
  methods: {
    clickCategory (event) {
      this.vertical = false
      this.$emit('click:category', event)
      this.computedCategory = event.item
    },
    resetCategory () {
      // Order matters for component animation purpose, computedSearchText must be emptied before unsetting the category
      this.computedSearchText = ''
      this.unsetCategory()
    },
    unsetCategory () {
      this.vertical = false
      this.computedCategory = undefined
    }
  },
  watch: {
    searchText: {
      immediate: true,
      handler (v) {
        this.innerSearchText = v
      }
    },
    selectedCategory: {
      immediate: true,
      handler (v) {
        this.category = v
      }
    },
    items (items) {
      if (items && !items.length && this.currentPage > 1) {
        this.currentPage = 1
      }
    }
  },
  props: {
    categories: {
      default: () => [],
      type: Array
    },
    categoryName: {
      default: 'name',
      type: String
    },
    displaySearch: {
      default: false,
      type: Boolean
    },
    itemKey: String,
    itemName: {
      default: 'name',
      type: String
    },
    hideCategories: {
      default: false,
      type: Boolean
    },
    items: {
      default: () => [],
      type: Array
    },
    iconProps: Object,
    label: {
      type: String,
      default () {
        return this.$t('t.Search')
      }
    },
    loading: {
      default: false,
      type: Boolean
    },
    pagesVisible: {
      default: 7,
      type: Number
    },
    pageSize: {
      required: true,
      type: Number
    },
    searchText: {
      default: '',
      type: String
    },
    selected: {
      type: [Object, Array, Number]
    },
    selectedCategory: {
      type: Object
    },
    selectedPage: {
      default: 1,
      type: Number
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-height
  height 100%

.pagination
  width min-content

.v-window-item
  display flex

.v-window>>>.v-window__container
  height 100%
</style>
